<div class="new-pop">
    <h1>You have earned a free item</h1>
    <!--<a  href="javascript:void(0);" (click)="onNoClick()" class="pop-cls">
      <svg id="Capa_1" enable-background="new 0 0 413.348 413.348" viewBox="0 0 413.348 413.348"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
      </svg>
    </a>-->
    <div class="Prd-bdy">
      <ngx-simplebar [options]="options">
        <div  class="menu-item" *ngFor="let dish of productList" [ngClass]="(businessDetails.inventory == 1 && dish.qty == 0)?'closedish':''">
            <div class="menu-pic" *ngIf="dish.is_img == 1">
              <img [lazyLoad]="dish.img" />
            </div>
            <div class="menu-pic" *ngIf="dish.is_img == 0 && dish.is_img1 == 1">
              <img [lazyLoad]="dish.img1" />
            </div>
            <div class="menu-pic" *ngIf="dish.is_img == 0 && dish.is_img1 == 0 && dish.is_img2 == 1">
              <img [lazyLoad]="dish.img2" />
            </div>
            <div class="menu-dtl" [ngClass]="{'w-100': dish.is_img == 0 && dish.is_img1 == 0 && dish.is_img2 == 0 }">
              <h5>
                <small class="vg-non">
                  <img *ngIf="dish.veg == 1" [lazyLoad]="vegImage" />
                  <img *ngIf="dish.nonveg == 1" [lazyLoad]="nonvegImage" />
                </small> {{dish.name}}</h5>
            </div>
            <div class="price-add ">

              <div>
                <span *ngIf="orderType != '2' && dish.offerprice > 0"
                  class="line-cut">{{businessDetails.currency_symbol}}{{dish.delprice}}</span>
                <span *ngIf="orderType == '2' && dish.pofferprice > 0"
                  class="line-cut">{{businessDetails.currency_symbol}}{{dish.pickprice}}</span>
                <span *ngIf="dish.price > 0">
                  {{businessDetails.currency_symbol}}{{dish.price}}
                </span>
                <a href="javascript:void(0);" (click)="openProduct(dish)">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </a>
              </div>
              <div class="cstmz-div">
                <span class="cstmz" *ngIf="dish.sets.length > 0">{{language.CUSTOMIZABLE}}</span>

              </div>
            </div>
            
            <div class="out_of_stock_label" *ngIf="dish.qty == 0">
              <label>Out Of Stock</label>
            </div>
          </div>
      </ngx-simplebar>
    </div>
  </div>
